import { FETCH_RESPONSE_ID, SHOW_FORMS } from "./types";
interface IntialState {
  externalForm: any,
  confirmationMessage: String
  answers: {}
}

const initialState: IntialState = {
  externalForm: {},
  confirmationMessage: "",
  answers: {}

};

export default function externalFormsReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_FORMS: {
      return {
        ...state,
        externalForm: payload
      }
    }
    case FETCH_RESPONSE_ID: {
      let newState = { ...state, confirmationMessage: payload?.confirmation_message, }
      if (state?.externalForm?.is_editable) {
        let resAnswers: any = {}
        payload?.response?.answers?.forEach((item: any) => {
          if (Object.hasOwn(item, "answer_options")) {
            resAnswers[item?.question_id] = item?.answer_options?.reduce(
              (object: any, item: any) => ({ ...object, [item?.id]: item }),
              {}
            );
          } else {
            resAnswers[item?.question_id] = item?.answer || "";
          }
        });
        newState = {
          ...newState, externalForm: { ...state?.externalForm, responseId: payload?.response?.id },
          answers: resAnswers
        }
      }
      return {
        ...newState
      }
    }
    default:
      return state;
  }
}
