import { cloneDeep, map } from "lodash";
import { ARCHIVE_FORM, CLEAR_FORM, CREATE_FORM, CREATE_INVITATIONS, CREATE_QUESTION, CREATE_SECTIONS, DELETE_FORM, DELETE_INVITATIONS, DELETE_QUESTION, DELETE_SECTIONS, GET_FORM_CHARTS, GET_FORM_QUESTION_ANSWERS, GET_FORM_QUESTIONS, GET_FORM_RESPONSES, GET_FORMS, GET_INVITATIONS, GET_RESPONSES_COUNT, GET_SETTINGS, MOVE_QUESTION, MOVE_SECTIONS, PREVIEW_FORM, PUBLISH_FORM, RESPONSES_OVER_TIME, UPDATE_FORM, UPDATE_QUESTION, UPDATE_SECTIONS, UPDATE_SETTINGS } from "./types";
import { processingForm } from "./utils";
import { FormsStatus } from "enums/forms";

interface IntialState {
  formsList: any
  originalForm: {}
  form: any
  sortedSectionsKeys: []
  dataResponsesOverTime: []
  responsesCount: 0,
  formSettings: [],
  invitations: { invitations: [], total: number },
  questions: { answers: [], total: number },
  responses: { items: [], total: number }
}

const initialState: IntialState = {
  formsList: {},
  originalForm: {},
  form: {},
  sortedSectionsKeys: [],
  dataResponsesOverTime: [],
  responsesCount: 0,
  formSettings: [],
  invitations: { invitations: [], total: 0 },
  questions: { answers: [], total: 0 },
  responses: { items: [], total: 0 }

};

export default function formsReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_FORMS: {
      let formsList = { ...state.formsList }
      let newItemsObj = payload?.show_responses?.reduce((object: any, item: any) => ({ ...object, [`${item?.id}-${item?.name}`]: item }), {})
      if (payload?.offset === 0) {
        formsList = { items: newItemsObj, total: payload?.total }
      } else {
        formsList = { ...formsList, items: { ...formsList?.items, ...newItemsObj } }
      }
      return {
        ...state,
        formsList
      }
    }
    case CREATE_FORM: {
      return {
        ...state,
        formsList: { ...state.formsList, items: { ...state?.formsList?.items, [`${payload?.id}-${payload?.name}`]: payload }, total: state?.formsList?.total + 1 }
      }
    }
    case DELETE_FORM: {
      let items: any = { ...state?.formsList?.items }
      delete items?.[`${payload?.id}-${payload?.name}`]
      return {
        ...state,
        formsList: { ...state?.formsList, items, total: state?.formsList?.total - 1 }
      }
    }
    case ARCHIVE_FORM: {
      return {
        ...state,
        formsList: {
          ...state.formsList,
          items: {
            ...state?.formsList?.items,
            [payload?.id]: { ...state?.formsList?.items?.[payload?.id], status: FormsStatus.ARCHIVED }
          }
        }
      }
    }
    case PUBLISH_FORM: {
      return {
        ...state,
        formsList: {
          ...state.formsList,
          items: {
            ...state?.formsList?.items,
            [payload?.id]:
              { ...state?.formsList?.items?.[payload?.id], status: FormsStatus.PUBLISHED }
          }
        }
      }
    }
    case PREVIEW_FORM: {
      let newPayload = processingForm(payload)
      return {
        ...state,
        form: newPayload.sortedForm,
        sortedSectionsKeys: newPayload.sortedSectionsKeys
      }
    }
    case UPDATE_FORM: {
      return {
        ...state,
        form: payload
      }
    }
    case CREATE_SECTIONS: {
      return {
        ...state,
        form: {
          ...state.form,
          sections: [
            ...state.form.sections,
            payload
          ]
        }
      }
    }
    case UPDATE_SECTIONS: {
      let newSections = [...state.form.sections]
      newSections[payload?.indexSection] = {
        ...newSections[payload?.indexSection],
        ...payload?.updatedData
      }
      return {
        ...state,
        form: {
          ...state.form,
          sections: newSections
        }
      }
    }
    case MOVE_SECTIONS: {
      let newSections: any = [...state.form?.sections]
      newSections = map((newSections), (s: any) => {
        if (Object.keys(payload || {}).includes(`${s?.id}`)) {
          return { ...s, order: payload?.[+s?.id]?.order }
        }
        return s
      })
      newSections.sort((a: any, b: any) => a.order - b.order)
      return {
        ...state,
        form: {
          ...state.form,
          sections: newSections
        }
      }
    }
    case DELETE_SECTIONS: {
      return {
        ...state,
        form: {
          ...state.form,
          sections: state.form.sections.filter((section: any) => section?.id !== payload)
        }

      }
    }
    case CREATE_QUESTION: {
      let newSections = [...state.form.sections]
      newSections[payload?.indexSection] = {
        ...newSections[payload?.indexSection] || {},
        questions: [
          ...newSections[payload?.indexSection]?.questions || [],
          payload?.updatedData
        ]
      }

      return {
        ...state,
        form: {
          ...state.form,
          sections: newSections
        }
      }
    }
    case UPDATE_QUESTION: {

      let sections = state.form.sections
      let newQuestions = sections[payload?.indexSection]?.questions
      newQuestions[payload?.indexQ] = {
        ...payload.updatedQuestion
      }
      return {
        ...state,
        form: {
          ...state.form,
          sections
        }
      }
    }
    case DELETE_QUESTION: {
      let newSections = [...state.form.sections]
      let newQuestions = [...newSections[payload?.indexSection]?.questions]
      newQuestions.splice(payload?.indexQ, 1);
      newSections[payload?.indexSection] = { ...newSections[payload?.indexSection], questions: newQuestions }
      return {
        ...state,
        form: {
          ...state.form,
          sections: newSections
        }

      }
    }
    case MOVE_QUESTION: {
      let newSections: any = cloneDeep(state.form?.sections)
      let newQuestions = [...newSections?.[payload?.indexSection]?.questions]
      let keysResReorder = (Object.keys(payload?.resReorder || {}) as string[]) || []
      newQuestions = newQuestions.map((q: any) => {
        if (keysResReorder.includes(`${q?.id}`)) {
          return { ...q, order: payload?.resReorder[+q?.id]?.order }
        }
        return q
      });
      newQuestions = newQuestions.sort((a: any, b: any) => a.order - b.order)
      // Ensure `payload.indexSection` is valid
      if (payload?.indexSection !== undefined && newSections[payload.indexSection] !== undefined) {
        newSections[payload.indexSection] = {
          ...newSections[payload.indexSection],
          questions: newQuestions,
        };
      }
      return {
        ...state,
        form: {
          ...state.form,
          sections: newSections
        }
      }
    }
    case GET_FORM_QUESTIONS: {
      let newData: any = { ...state.questions }
      if (payload?.offset === 0) {
        newData = { answers: payload?.answers, total: payload?.total }
      } else {
        newData = { ...newData, answers: [...newData?.answers, ...payload?.answers] }
      }
      return {
        ...state,
        questions: newData
      }
    }
    case GET_FORM_CHARTS: {
      let newQuestions: any = state.questions
      let selectedIndex: number = state?.questions?.answers?.findIndex((i: any) => i?.question_id === payload?.id)
      if (selectedIndex !== -1) {
        newQuestions[selectedIndex] = {
          ...newQuestions[selectedIndex],
          charts: payload?.results

        }
      }

      return {
        ...state,
        questions: newQuestions
      }
    }
    case GET_FORM_QUESTION_ANSWERS: {
      let newQuestions: any = cloneDeep(state.questions)
      let newAnswers = newQuestions?.answers
      let selectedIndex: number = state?.questions?.answers?.findIndex((i: any) => i?.question_id === payload?.question_id)
      if (selectedIndex !== -1) {
        newAnswers[selectedIndex] = {
          ...newAnswers[selectedIndex],
          answers: [...newAnswers[selectedIndex]?.answers, ...payload?.answers?.[0]?.answers || []],
          total: payload?.total || newAnswers[selectedIndex]?.total
        }
        newQuestions = {
          ...newQuestions,
          answers: newAnswers
        }
      }
      return {
        ...state,
        questions: newQuestions
      }
    }
    case RESPONSES_OVER_TIME: {
      return {
        ...state,
        dataResponsesOverTime: payload
      }
    }
    case GET_RESPONSES_COUNT: {
      return {
        ...state,
        responsesCount: payload?.Count || 0
      }
    }
    case GET_SETTINGS: {
      return {
        ...state,
        formSettings: payload
      }
    }
    case UPDATE_SETTINGS: {
      return {
        ...state,
        formSettings: payload
      }
    }
    case GET_INVITATIONS: {
      let invitations: any = { ...state.invitations }
      if (payload?.offset === 0) {
        delete payload?.offset
        invitations = payload
      } else {
        invitations = { ...invitations, invitations: [...invitations?.invitations, ...payload?.invitations] }
      }
      return {
        ...state,
        invitations
      }
    }
    case CREATE_INVITATIONS: {
      return {
        ...state,
        invitations: {
          ...state?.invitations, invitations: [...state?.invitations?.invitations || [], ...payload],
          total: (state?.invitations?.total || 0) + 1
        }
      }
    }
    case DELETE_INVITATIONS: {

      let invitationsObj = [...state?.invitations?.invitations].reduce((object: any, item: any) => ({ ...object, [item?.id]: item }), {})

      for (const item of payload) {
        delete invitationsObj[item]

      }
      return {
        ...state,
        invitations: { ...state?.invitations, invitations: Object.values(invitationsObj), total: state?.invitations?.total - 1 }
      }
    }
    case GET_FORM_RESPONSES: {
      let responses: any = { ...state.responses }
      let res = payload?.replies?.reduce((object: any, item: any) => ({ ...object, [item?.id]: item }), {})
      if (payload?.offset === 0) {
        delete payload?.offset
        responses = {
          items: { ...res },
          total: payload?.total
        }
      } else {
        responses = {
          ...state?.responses,
          items: { ...state?.responses?.items, ...res }
        }
      }
      return {
        ...state,
        responses
      }
    }
    case CLEAR_FORM: {
      return {
        ...state,
        originalForm: {},
        form: {},
        sortedSectionsKeys: [],
        dataResponsesOverTime: [],
        responsesCount: 0,
        formSettings: [],
        invitations: { invitations: [], total: 0 },
        questions: { answers: [], total: 0 },
        responses: { items: [], total: 0 }
      }
    }
    default:
      return state;
  }
}
