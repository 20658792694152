export enum FormsStatus {
    PUBLISHED = "PUBLISHED",
    ARCHIVED = "ARCHIVED",
    DRAFT = "DRAFT"
}

export enum FormsFieldsType {
    STRING = "string",
    INT = "int",
    BOOL = "bool",
    TIME = "time"
}

export enum FormsTabs {
    QUESTIONS = "questions",
    RESPONSES = "responses",
    SETTINGS = "setting",
    INVITATIONS = "invitations"
}

export enum FormsQuestionTypes {
    MULTIPLE_CHOICE_RADIO_BUTTON = "MULTIPLE_CHOICE_RADIO_BUTTON",
    TEXT_SHORT_ANSWER = "TEXT_SHORT_ANSWER",
    CHECKBOX = "CHECKBOX",
    DROPDOWN = "DROPDOWN",
    PARAGRAPH = "PARAGRAPH",
    FILE_UPLOAD = "FILE_UPLOAD",
    LINEAR_SCALE = "LINEAR_SCALE",
    DATE = "DATE",
    TIME = "TIME",
    MOBILE = "Mobile",
    DIGITS = "DIGITS"
}
export enum ResponseQuestionTabs {
    ANSWERS = "answers",
    CHARTS = "charts"
}

export enum FilesTypes {
    IMAGE = "image",
    VIDEO = "video",
    AUDIO = "audio"
}
export enum FormsFilesTypes {
    DOCUMENTT = "document",
    PRESENTATION = "presentation",
    SPREADSHEET = "spreadsheet",
    DRAWING = "drawing",
    PDF = "pdf",
    IMAGE = "image",
    VIDEO = "video",
    AUDIO = "audio"
}
export enum ChoiceTypes {
    TEXT = "text",
    IMAGE = "Image",
}