export const processingForm = (data: any) => {
    // let newData = { ...data }
    let sortedSectionsKeys: any = []
    let sortedQuestionsKeys: any = {}
    let sortedForm = {
        ...data,
        sections: data.sections
            .sort((a: any, b: any) => a.order - b.order) // Sort sections by their order
        // .map((section: any) => ({
        //     ...section,
        //     questions: section?.questions?.sort((a: any, b: any) => a.order - b.order) // Sort questions by their order
        // }))
    };
    return { sortedForm, sortedSectionsKeys, sortedQuestionsKeys }
}