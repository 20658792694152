export const GET_FORMS = "GET_FORMS";
export const PREVIEW_FORM = "PREVIEW_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const CREATE_FORM = "CREATE_FORM"
export const DELETE_FORM = "DELETE_FORM"
export const PUBLISH_FORM = "PUBLISH_FORM"
export const ARCHIVE_FORM = "ARCHIVE_FORM"
/**sections */
export const CREATE_SECTIONS = "CREATE_SECTIONS"
export const UPDATE_SECTIONS = "UPDATE_SECTIONS"
export const DELETE_SECTIONS = "DELETE_SECTIONS"
export const MOVE_SECTIONS = "MOVE_SECTIONS"
/**question */
export const CREATE_QUESTION = "CREATE_QUESTION"
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const MOVE_QUESTION = "MOVE_QUESTION"
/**responses */
export const GET_RESPONSES_COUNT = "GET_RESPONSES_COUNT"
export const GET_FORM_RESPONSES = "GET_FORM_RESPONSES"
export const DOWNLOAD_EXCEL_FILE = "DOWNLOAD_EXCEL_FILE"
export const RESPONSES_OVER_TIME = "RESPONSES_OVER_TIME"
export const GET_FORM_QUESTIONS = "GET_FORM_QUESTIONS"
export const GET_FORM_QUESTION_ANSWERS = "GET_FORM_QUESTION_ANSWERS"
export const GET_FORM_CHARTS = "GET_FORM_CHARTS"
/**settings */
export const GET_SETTINGS = "GET_SETTINGS"
export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
/**invitations */
export const GET_INVITATIONS = "GET_INVITATIONS"
export const CREATE_INVITATIONS = "CREATE_INVITATIONS"
export const DELETE_INVITATIONS = "DELETE_INVITATIONS"
export const GET_INVITATIONS_CHANGE_LIMITS = "GET_INVITATIONS_CHANGE_LIMITS"

export const CLEAR_FORM = "CLEAR_FORM"