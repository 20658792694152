import { FixedData } from "./type";
import { Theme } from "@mui/material/styles";
import { TFunction } from "react-i18next";
import { ReactComponent as DashBoardIcon } from "assets/nav-bar/dashboard.svg";
//import { ReactComponent as CommunityIcon } from "assets/nav-bar/Community.svg";
import { ReactComponent as MessageIcon } from "assets/nav-bar/messagings.svg";
//import { ReactComponent as QuesAnswerIcon } from "assets/nav-bar/Q&A.svg";
import { ReactComponent as SettingIcon } from "assets/nav-bar/setting.svg";
import { ReactComponent as CampaignIcon } from "assets/nav-bar/Campaign.svg";
import { ReactComponent as FlowIcon } from "assets/nav-bar/flow.svg";
import { ReactComponent as SubscripeIcon } from "assets/subscripe/subscripe.svg";
import { ReactComponent as ReportsIcon } from "assets/nav-bar/report.svg";
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { TabsPermission } from "enums/tabsPermissions";
import { UserRoles } from "enums/roles";
import { checkTabPermissions } from "utils/helper";
import {
  FLOW_ROUTES,
  FORMS_ROUTES,
  MAIN_ROUTES,
  REPORTS_ROUTES,
  Routes,
  SETTING_ROUTES,
  SUBSCRIPTION_ROUTES,
} from "enums/routes";

const menuController: any = (
  t: TFunction<"translation">,
  theme: Theme,
  text: string,
  value: string,
  id: number,
  icon: object,
  opacity: any,
  svgWidth = "1.5em"
) => {
  return {
    id: id,
    value: value,
    text: t(text),
    listItemStyle: {
      opacity: opacity ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      // fontSize:largnPcMatch?"0.7rem":'0.85rem',
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
    },
    svgIcon: icon,
    svgStyle: {
      fontSize: "1rem",
      width: "1.5em",
    },
    kind: "normal",
    textStyle: {
      fontSize: "1.2rem",
    },
  };
};

export const fixedNavData: (
  t: TFunction<"translation">,
  theme: Theme,
  pathname: Routes,
  profile: any
) => FixedData[] = (t, theme, pathname, profile) => [
  checkTabPermissions(profile, TabsPermission.VIEW_DASHBOARD_TAB)
    ? menuController(
      t,
      theme,
      "dashboard",
      "1",
      601,
      DashBoardIcon,
      pathname === Routes.DASHBOARD
    )
    : null,
  checkTabPermissions(profile, TabsPermission.VIEW_INBOX_TAB) &&
    profile.role !== UserRoles.COMPANY_ADMIN
    ? menuController(
      t,
      theme,
      "message",
      "2",
      602,
      MessageIcon,
      pathname === Routes.CHAT
    )
    : null,
  checkTabPermissions(profile, TabsPermission.VIEW_REQUEST_TAB)
    ? menuController(
      t,
      theme,
      "request",
      "10",
      610,
      MessageIcon,
      pathname === Routes.REQUEST
    )
    : null,
  //   menuController(
  //     t,
  //     theme,
  //     "community",
  //     "3",
  //     603,
  //     CommunityIcon,
  //     pathname === Routes.COMMUNITY
  //   ),
  //   menuController(
  //     t,
  //     theme,
  //     "q&a",
  //     "4",
  //     604,
  //     QuesAnswerIcon,
  //     COMMUNITY_ROUTES.includes(pathname)
  //   ),

  checkTabPermissions(profile, TabsPermission.VIEW_CAMPAIGN_TAB)
    ? menuController(
      t,
      theme,
      "campaign",
      "5",
      605,
      CampaignIcon,
      pathname === Routes.CAMPAIGN,
      "2.2em"
    )
    : null,

  checkTabPermissions(profile, TabsPermission.VIEW_FLOW_TAB)
    ? menuController(
      t,
      theme,
      "flow",
      "7",
      607,
      FlowIcon,
      FLOW_ROUTES.includes(pathname)
    )
    : null,
  menuController(
    t,
    theme,
    "setting",
    "6",
    606,
    SettingIcon,
    SETTING_ROUTES.includes(pathname)
  ),
  menuController(
    t,
    theme,
    "reports",
    "8",
    308,
    ReportsIcon,
    REPORTS_ROUTES.includes(pathname)
  ),
  menuController(
    t,
    theme,
    "forms",
    "13",
    400,
    DynamicFormIcon,
    FORMS_ROUTES.includes(pathname)
  ),
  menuController(
    t,
    theme,
    "subscribtion",
    "9",
    609,
    SubscripeIcon,
    SUBSCRIPTION_ROUTES.includes(pathname)
  ),
];

// convert main routes arr to obj
export const valuesUrl: any = MAIN_ROUTES.reduce(
  (a, v, i) => ({ ...a, [++i]: v }),
  {}
);
